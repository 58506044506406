import Earthnauts from "../articles/Earthnauts";
import Artashastra from "../articles/Artashastra";
import EconomicallyVegetarian from "../articles/EconomicallyVegetarian";
import OdeToProgramming from "../articles/OdeToProgramming";
import Career from "../articles/Career";
import TimeBroker from "../articles/TimeBroker";
import CivilDisobedience from "../articles/CivilDisobedience";
import TheoryOfReligion from "../articles/TheoryOfReligion";
import DecisionMaking from "../articles/DecisionMaking";
import MentalWellness from "../articles/MentalWellness";
import DoingGoodBetter from "../articles/DoingGoodBetter";
import CiudadDeBuenosAires from "../articles/CiudadDeBuenosAires";
import SailingMombasa from "../articles/SailingMombasa";
import ImbalancedMachineLearning from "../articles/MachineLearningImbalanced";
import CiudadDeMexico from "../articles/CiudadDeMexico";
import WhyKhoj from "../articles/WhyKhoj";
import AISafety from "../articles/AISafety";

import { ReferenceLink } from "../components/article/ReferenceBox";

export const topics = {
    BOOK: "book",
    SOCIETY: "society",
    FOOD: "food",
    ETHICS: "ethics",
    SUSTAINABILITY: "sustainability",
    RAMBLE: "discourse",
    FUTURE: "future",
    TECHNOLOGY: "technology",
    TRAVEL: "travel",
};

export const supplements = {
    ALGORITHMS_TO_LIVE_BY: new ReferenceLink("Algorithms to Live By, Brian Christian and Tom Griffiths", "https://www.goodreads.com/book/show/25666050-algorithms-to-live-by"),
    HACKERS_AND_PAINTERS: new ReferenceLink("Hackers and Painters, Paul Graham", "http://www.paulgraham.com/hackpaint.html"),
    DATA_INTENSIVE_APPLICATIONS: new ReferenceLink("Designing Data-Intensive Applications, Martin Kleppmann", "https://dataintensive.net/"),
    ARTASHASTRA: new ReferenceLink("Artashastra, Kautilya", "https://en.wikipedia.org/wiki/Arthashastra"),
    NATURE_CARBON_CALCULATOR: new ReferenceLink("Nature.org Carbon Footprint Calculator", "https://www.nature.org/en-us/get-involved/how-to-help/carbon-footprint-calculator/"), 
    LET_MY_PEOPLE_GO_SURFING_CHOUINARD: new ReferenceLink("Let My People Go Surfing, Yvon Chouinard", "https://www.goodreads.com/book/show/22155.Let_My_People_Go_Surfing"),
    THINKING_FAST_AND_FLOW: new ReferenceLink("Thinking, Fast and Slow, Daniel Kahneman", "https://www.goodreads.com/book/show/11468377-thinking-fast-and-slow"),
    SAPIENS_HARARI: new ReferenceLink("Sapiens, Yuval Noah Harari", "https://www.goodreads.com/book/show/23692271-sapiens"), 
    SELFISH_GENE_DAWKINS: new ReferenceLink("Selfish Gene, Richard Dawkins", "https://www.goodreads.com/book/show/61535.The_Selfish_Gene"),
    INNOVATORS_ISAACSON: new ReferenceLink("The Innovators, Walter Isaacson", "https://en.wikipedia.org/wiki/The_Innovators_(book)"),
    EIGHTY_THOUSAND_HOURS: new ReferenceLink("80000 Hours - Key Ideas", "https://80000hours.org/key-ideas/"),
    WAIT_BUT_WHY_CAREER: new ReferenceLink("Wait But Why - 80000 Hours", "https://waitbutwhy.com/2018/04/picking-career.html"),
    FARNAM_STREET_MAKERS_MANAGERS: new ReferenceLink("Farnam Street: Makers vs. Managers", "https://fs.blog/maker-vs-manager/"),
    SUMMARY_DEEP_WORK_NEWPORT: new ReferenceLink("Summary of Deep Work, Cal Newport", "https://whatisthebusinessmodelof.com/business-books/deep-work-book-summary/"),
    CIVIL_DISOBEDIENCE: new ReferenceLink("Civil Disobedience, Stanford Encylopedia of Philosophy", "https://plato.stanford.edu/entries/civil-disobedience/"),
    MEANING_CRISIS: new ReferenceLink("Meaning Crisis", "https://www.meaningcrisis.co/all-transcripts/"),
    GODS_POWER_FROM_BELIEF: new ReferenceLink("The Gods Only Have Power Because We Believe In Them, Scott Alexander", "https://astralcodexten.substack.com/p/the-gods-only-have-power-because?s=r"),
    POWER_OF_MYTH: new ReferenceLink("Power of Myth, Joseph Campbell", "https://billmoyers.com/content/ep-6-joseph-campbell-and-the-power-of-myth-masks-of-eternity-audio/"),
    CAVE_FORGOTTEN_DREAMS: new ReferenceLink("Cave of Forgotten Dreams, Werner Herzog", "https://en.wikipedia.org/wiki/Cave_of_Forgotten_Dreams"),
    GRABBY_ALIENS: new ReferenceLink("Grabby Aliens", "https://grabbyaliens.com/"),
    SUPERFORECASTING: new ReferenceLink("Superforecasting", "https://en.wikipedia.org/wiki/Superforecasting:_The_Art_and_Science_of_Prediction"),
    HAPPINESS_LAB: new ReferenceLink("Happiness Lab", "https://www.happinesslab.fm/"),
    THE_PRECIPICE: new ReferenceLink("The Precipice, Tony Ord", "https://theprecipice.com/"),
    YAHGAN_PEOPLE: new ReferenceLink("Yahgan People", "https://en.wikipedia.org/wiki/Yahgan_people"),
    MOXIE_MARLINSPIKE_HOLD_FAST: new ReferenceLink("Moxie Marlinspike - Hold Fast", "https://vimeo.com/15351476"),
    SEMI_SUPERVISED_LEARNING_EMR: new ReferenceLink("Semi-Supervised Approaches to Efficient Evaluation of Model Prediction Performance", "https://arxiv.org/pdf/1711.05663.pdf"),
    MERCHANT_OF_LANDSCAPES: new ReferenceLink("‘Merchant of Landscapes’: The Lasting Footprint of a Japanese Gardener in Mexico", "https://web.archive.org/web/20230325130727/https://www.nytimes.com/2023/03/25/world/americas/mexico-city-jacarandas.html"),
    CHALLENGE_VALUE_ALIGNMENT: new ReferenceLink("The Challenge of Value Alignment: from Fairer Algorithms to AI Safety", "https://arxiv.org/pdf/2101.06060.pdf"),
    CHALLENGES_OF_AI_REGULATION: new ReferenceLink("The Challenges of Regulating Artificial Intelligence", "https://www.brookings.edu/articles/the-three-challenges-of-ai-regulation/"),
    NEW_COMERS_GUIDE_TO_AI_RISK: new ReferenceLink("A Newcomer’s Guide to AI Risk", "https://www.lesswrong.com/posts/5rsa37pBjo4Cf9fkE/a-newcomer-s-guide-to-the-technical-ai-safety-field"),
};

export var database = [
    {
        path: "/earthnauts",
        title: "Earthnauts",
        preview: "We are all explorers of our home planet. Is there anything that makes us special in this regard? Is there something we were engineered to be best at?",
        date: new Date("2021-08-07"),
        component: Earthnauts,
        topics: new Set([ topics.SOCIETY, topics.RAMBLE, topics.FUTURE ]),
        supplements: [supplements.SAPIENS_HARARI, supplements.SELFISH_GENE_DAWKINS, supplements.ALGORITHMS_TO_LIVE_BY]
    },
    {
        path: "/veggievironment",
        title: "Economically Vegetarian",
        preview: "Sometimes I like to imagine that my pseudo-vegetarian diet is saving the planet, but I wonder if the decision really holds economic and impact-based merit.",
        date: new Date("2021-08-12"),
        component: EconomicallyVegetarian,
        topics: new Set([ topics.SUSTAINABILITY, topics.FOOD, topics.ETHICS, topics.RAMBLE ]),
        supplements: [supplements.NATURE_CARBON_CALCULATOR, supplements.LET_MY_PEOPLE_GO_SURFING_CHOUINARD, supplements.THINKING_FAST_AND_FLOW]
    },
    {
        path: "/artashastra",
        title: "Artashastra: Thoughts",
        preview: "My thoughts of the book Artashastra, written by Kautilya sometime around year 0 AD. This text is best described as a state's manual for Ancient Indian Kingdoms, but provides some useful insights into industry and society today.",
        date: new Date("2021-09-10"),
        component: Artashastra,
        topics: new Set([ topics.BOOK, topics.SOCIETY, topics.ETHICS ]),
        supplements: [supplements.ARTASHASTRA]
    },
    {
        path: "/devlove",
        title: "An Ode to Programming",
        preview: "Being a programmer gives me a sense of wonder and self-efficacy. It helps me see the world more clearly, and allows me to engage with my surroundings in meaningful ways. This is basically a love letter to writing code.",
        date: new Date("2021-09-19"),
        component: OdeToProgramming,
        topics: new Set([ topics.SOCIETY, topics.TECHNOLOGY, topics.RAMBLE ]),
        supplements: [supplements.ALGORITHMS_TO_LIVE_BY, supplements.HACKERS_AND_PAINTERS, supplements.DATA_INTENSIVE_APPLICATIONS, supplements.INNOVATORS_ISAACSON]
    },
    {
        path: "/maqsad",
        title: "Career",
        preview: "Our life plans are often fraught with loopholes. We look for ways to find meaning in our fleeting blink of a life, taking uncertain twists and turns along the journey.",
        date: new Date("2021-10-05"),
        component: Career,
        topics: new Set([ topics.SOCIETY, topics.FUTURE ]),
        supplements: [supplements.EIGHTY_THOUSAND_HOURS, supplements.WAIT_BUT_WHY_CAREER]
    },
    {
        path: "/timebroker",
        title: "Time Broker",
        preview: "We have to be extremely frugal over our time, less the world keep extracting more from us. Do one thing at a time, and get more value for your effort.",
        date: new Date("2021-12-31"),
        component: TimeBroker,
        topics: new Set([ topics.SOCIETY, topics.RAMBLE ]),
        supplements: [supplements.FARNAM_STREET_MAKERS_MANAGERS, supplements.SUMMARY_DEEP_WORK_NEWPORT]
    },
    {
        path: "/civildisobedience",
        title: "Progressive Civility",
        preview: "In our imagined annals of justice, we need to stand up for what is right to create a more equal society. The romantic ideals of humanity and equality need to be actively pursued.",
        date: new Date("2022-01-17"),
        component: CivilDisobedience,
        topics: new Set([ topics.SOCIETY, topics.ETHICS, topics.RAMBLE ]),
        supplements: [supplements.CIVIL_DISOBEDIENCE]
    },
    {
        path: "/whomadeus",
        title: "Theory of Religion",
        preview: "Since the dawn of humanity, we have searched for meaning. Civilizations have turned to mythical explanations borne of the imagination to understand how the world came to be. As time passes, we reflect on what these stories say about people themselves.",
        date: new Date("2022-03-06"),
        component: TheoryOfReligion,
        topics: new Set([ topics.SOCIETY, topics.ETHICS, topics.FUTURE ]),
        supplements: [supplements.MEANING_CRISIS, supplements.GODS_POWER_FROM_BELIEF, supplements.POWER_OF_MYTH, supplements.SELFISH_GENE_DAWKINS, supplements.GRABBY_ALIENS]
    },
    {
        path: "/decisionmaking",
        title: "Wander, Forecast, Iterate: Thoughts on Better Decision-Making",
        preview: "We suck at making decisions. Our brains are adept storytellers that dislike being measured against reality. Enmeshed in our biases, we actively reject information that proves us false. Making better decision is of existential importance to humanity. Systems that safeguard against these common failure modes are vital to improve our collective decision-making.",
        date: new Date("2022-07-03"),
        component: DecisionMaking,
        topics: new Set([ topics.SOCIETY, topics.ETHICS, topics.RAMBLE ]),
        supplements: [supplements.SUPERFORECASTING]
    },
    {
        path: "/mindnuts",
        title: "Meditations on Meditation",
        preview: "Sometimes, our brains need a little pruning.",
        date: new Date("2022-09-11"),
        component: MentalWellness,
        topics: new Set([ topics.SOCIETY, topics.RAMBLE ]),
        supplements: [supplements.HAPPINESS_LAB]
    },
    {
        path: "/dogood",
        title: "Doing Good Better",
        preview: "How do we do good in a more quantitative, data-driven method?",
        date: new Date("2022-10-07"),
        component: DoingGoodBetter,
        topics: new Set([ topics.BOOK, topics.SOCIETY, topics.ETHICS ]),
        supplements: [ supplements.THE_PRECIPICE ]
    },
    {
        path: "/ba",
        title: "Ciudad de Buenos Aires",
        preview: "Brief thoughts after one week in Argentina's capital.",
        date: new Date("2022-12-05"),
        component: CiudadDeBuenosAires,
        topics: new Set([ topics.SOCIETY, topics.TRAVEL ]),
        supplements: [supplements.YAHGAN_PEOPLE]
    },
    {
        path: "/sailingmombasa",
        title: "Sailing Mombasa",
        preview: "Recounting the dynamics of a sailing stint in Mombasa, Kenya.",
        date: new Date("2022-09-13"),
        component: SailingMombasa,
        topics: new Set([ topics.SOCIETY, topics.TRAVEL ]),
        supplements: [supplements.MOXIE_MARLINSPIKE_HOLD_FAST]
    },
    {
        path: "/imbalanced-ml",
        title: "Machine Learning for Imbalanced Datasets: A Guide for Engineers",
        preview: "Machine learning models are often trained on imbalanced datasets. This leads to poor performance on the minority class. This post discusses some techniques to address this problem.",
        date: new Date("2023-04-07"),
        component: ImbalancedMachineLearning,
        topics: new Set([ topics.SOCIETY, topics.TECHNOLOGY ]),
        supplements: [supplements.SEMI_SUPERVISED_LEARNING_EMR],
    },
    {
        path: "/cdmx",
        title: "Ciudad de México",
        preview: "After three months of living in Mexico City, I'm firmly convinced that it's one of the most livable cities I've ever visited.",
        date: new Date("2023-04-30"),
        component: CiudadDeMexico,
        topics: new Set([ topics.SOCIETY, topics.TRAVEL ]),
        supplements: [supplements.MERCHANT_OF_LANDSCAPES]
    },
    {
        path: "/khoj",
        title: "Why I'm working on an AI startup",
        preview: "I decided to start Khoj to build a trustworthy, accessible personal AI assistant for everyday users. I outline some of the reasons why in this post.",
        date: new Date("2023-07-22"),
        component: WhyKhoj,
        topics: new Set([ topics.SOCIETY, topics.ETHICS, topics.TECHNOLOGY ]),
        supplements: [supplements.CHALLENGE_VALUE_ALIGNMENT],
    },
    {
        path: "/aisafety",
        title: "AI Regulation Risk",
        preview: "It's important to think deeply about guidelines around AI safety and risk. It's critical that we continue to build in the open to protect against unilateral control of AI.",
        date: new Date("2023-11-04"),
        component: AISafety,
        topics: new Set([ topics.SOCIETY, topics.ETHICS, topics.TECHNOLOGY ]),
        supplements: [supplements.CHALLENGES_OF_AI_REGULATION, supplements.NEW_COMERS_GUIDE_TO_AI_RISK],
    }
]