// Stylesheets
import './style/App.css';

// Core pages
import Home from './Home';
import About from './About';
import Subscription from './Subscription';

// Articles to render
import { database } from './common/database';

// Article component
import Article from './components/article/Article';

// Assets
import { ReactComponent as Earth } from './assets/earth.svg';

// External components
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
} from "react-router-dom";

function App() {
	const routes = database.map((article) => {
		return (
		<Route 
			key={article.path}
			path={article.path}
			component={
				() => <Article body={article.component} title={article.title} topics={article.topics} references={article.supplements} date={article.date} />
			}>
		</Route>);
	});

	return (
		<Router>
			<div className="App">
				<header className="App-header">
					<div className="App-title-header">
						<Link to="/">
							<Earth className="logo" />
						</Link>
						<Link to="/">
							<p className="App-header">
								upbeat moon
							</p>
						</Link>
					</div>
					<div className="App-nav">
						<Link className="menu" to="/about">
							<p className="App-subheader">
								about
							</p>
						</Link>
						<Link className="menu" to="/subscription">
							<p className="App-subheader">
								subscribe
							</p>
						</Link>
					</div>
				</header>
				<Switch>
					{ routes }
					<Route path="/about">
						<About />
					</Route>
					<Route path="/subscription">
						<Subscription />
					</Route>
					<Route path="/topics/:topics" component={Home} />
					<Route path="/">
						<Home />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}

export default App;
